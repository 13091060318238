import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const LetsChat = () => {
  return (
    <Container fluid className="bg-blue text-white py-5">
      <Container className="text-center ">
        <p className="mb-4 h3">Let's chat about dedicated BI solutions for your business...</p>
        <p className="mb-4 h2 font-weight-thin">1300 509 976</p>
        <Link className="btn btn-light btn-xl booknow" to="/contact/">Contact Us</Link>
      </Container>
    </Container>
  );
};

export default LetsChat;
