import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import LogoLightIMG from "../img/logo-light.svg"
import LogoQlikWhite from "../img/logo-qlik-white.svg"
import LogoPowerBIWhite from "../img/logo-powerbi-white.svg"

const Footer = (props) => {
  return (
    <Container fluid className="bg-blue py-5 container_footer">
      <Container className="text-center my-5">
        <img src={LogoLightIMG} alt="SolveBI - Business Intelligence Implementation Strategy" width="150" height="40" className="mb-2" style={{ opacity: "0.6" }} />
        <h3 className="text-white-50 font-weight-thin h3">Helping businesses all over the world build a data-driven culture</h3>
        <hr style={{ "border": "1px solid #ffff", "width": "80px" }} className="mt-5"></hr>
        <div className="text-white mt-4">
          <p>We are <Link to="/partners/" className="text-white">implementation partner for Qlik and Microsoft Power BI</Link></p>
          <img src={LogoQlikWhite} alt="Official Partner for Qlik Sense" width="120" height="40" className="mb-2 mr-2" />
          <img src={LogoPowerBIWhite} alt="Official Partner for Power BI" width="120" height="40" className="mb-2 ml-2" />
        </div>
        <div className="mt-5 small text-white-50">
          <Link to="/privacy/" rel="nofollow" className="text-white">Privacy Policy</Link> | <Link to="/terms/" rel="nofollow" className="text-white">Legal</Link>
          <p>&copy; {(new Date().getFullYear())} SolveBI Australia. Perth | Brisbane | Sydney | Malbourne</p>
          <Link to="/partners/qlik/" className="text-white">Qlik</Link> &amp; <Link to="/partners/" className="text-white">Power BI</Link> Implementation, Integration &amp; Visualisation
          <p>All Rights Reserved</p>
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
